import styled from "@emotion/styled";
import { UmpireRed, UmpireOrange, UmpireBlue } from "assets/images";
import { numberInfo, sizes, statusColors } from "service";

export const NumberContainer = styled.div`
  position: relative;
  width: ${sizes.numberWidth}px;
  height: ${sizes.numberWidth}px;
  border-radius: 9px;
  background: url(${(props) => numberInfo[props.status].background});
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Varsity Team Bold";
  &.number7 {
    ${prop => prop.status === statusColors.orange ? `
      background: url(${UmpireOrange});
    ` : ``};
    ${prop => prop.status === statusColors.blue ? `
      background: url(${UmpireBlue});
    ` : ``};
    ${prop => prop.status === statusColors.white ? `
      background: url(${UmpireRed});
    ` : ``};
    background-size: 100% 100%;
    background-repeat: no-repeat;
    span {
      color: rgba(0, 0, 0, 0) !important;
    }
  }
  span {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-family: "Varsity Team Bold" !important;
  }
  .icon-check-num,
  .icon-free-num {
    position: absolute;
    opacity: 0.9;
  }
  .add-score-value {
    position: absolute;
  }

  .dust {
    position: absolute;
    width: 350px;
    height: 350px;
  }

  .hide-dust {
    display: none;
  }

  .dust-show {
    animation: dust-out 0.5s ease-out 1.5s forwards;
  }

  @keyframes dust-out {
    0% { opacity: 1; }
    100% { opacity: 0.5; }
  }

  .umpire {
    width: 80px;
    height: 95px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 2px;
  }
`;
